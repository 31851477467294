import React from "react";
import Layout from "../components/Layout";

import "./404.scss";

const NotFoundPage = () => (
  <Layout>
    <div className="Layout__body">
      <div className="Error404">
        <h1>Erreur 404</h1>
        <h2>Page introuvable</h2>
        <p>La page à laquelle vous essayez d'accéder semble ne pas exister</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
